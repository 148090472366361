export default class ProductHeader {
  constructor () {
    if ($('#header').length) $(window).scroll(this.menuUpdate).scroll()
    if ($('#header-profile').length) $(window).scroll(this.menuProfileSticky).scroll()
    if ($('.htr-banner-fix').length) $(window).scroll(this.bannerUpdate).scroll()
    if ($('#profiles-mobile-top-bar-slider').length) {
      if ($('#profiles-mobile-top-bar-slider-content').length) $('#profiles-mobile-top-bar-slider-content').scroll(this.menuMobileHideIconsProfileSticky)
      $(window).scroll(this.menuMobileProfileSticky).scroll()
    }
    if (window.googletag){
      googletag.cmd.push(() => {
        googletag.pubads().addEventListener('slotRenderEnded', () => {
          this.menuUpdate()
          this.menuProfileSticky()
          this.bannerUpdate()
        })
      })
    }
  }

  bannerUpdate() {
    const heightBanner = $('.htr-banner-fix').height()
    if($('.top-bar').length) {
      $('.top-bar').css('margin-top', heightBanner)
    }
    else {
      $('#wrapper').css('margin-top', heightBanner)
    }
    let scroll = $(document).scrollTop()

    if (scroll > heightBanner) {
      $('.htr-banner-fix').css('display', 'none')
    }
    else {
      $('.htr-banner-fix').css('display', 'flex')
    }
  }

  menuUpdate () {
    let scroll = $(document).scrollTop()
    const heightBanner = $('.htr-banner-fix').height() || 45
    if (scroll > heightBanner ) {
      $('#header').addClass('active')
      $('#header').removeClass('positionRelative')
    } else {
      if (!window.Laravel.header.alwaysActive) {
        $('#header').removeClass('active')
      } else {
        $('#header').addClass('positionRelative')
      }
    }
  }

  menuProfileSticky () {
    let scroll = $(document).scrollTop()

    if (scroll > 200) {
      $('#header-profile').addClass('active')
    } else {
      $('#header-profile').removeClass('active')
    }
  }

  menuMobileProfileSticky () {
    let scroll = $(document).scrollTop()

    if (scroll > 200) {
      $('#profiles-mobile-top-bar-slider').addClass('active')
    } else {
      $('#profiles-mobile-top-bar-slider').removeClass('active')
    }
  }

  menuMobileHideIconsProfileSticky () {
    let scroll = $('#profiles-mobile-top-bar-slider-content').scrollLeft()
    let scrollMax = document.getElementById('profiles-mobile-top-bar-slider-content').scrollWidth
    let documentWidth = $(document).width();
    
    if (documentWidth > scrollMax + 20) {
      return 0
    }

    if (scroll < 10) {
      $('#profiles-mobile-top-bar-slider-left-icon').css('visibility', 'hidden')
      $('#profiles-mobile-top-bar-slider-right-icon').css('visibility', 'inherit')
    } else if (scroll > 10 && scroll < (scrollMax - documentWidth - 10)) {
      $('#profiles-mobile-top-bar-slider-left-icon').css('visibility', 'inherit')
      $('#profiles-mobile-top-bar-slider-right-icon').css('visibility', 'inherit')
    } else {
      $('#profiles-mobile-top-bar-slider-left-icon').css('visibility', 'inherit')
      $('#profiles-mobile-top-bar-slider-right-icon').css('visibility', 'hidden')
    }
  }
}
